/* General Styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  color: black;
  box-sizing: border-box;
}

.container {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

/* Page-Specific Backgrounds */
.home-container {
  background-image: url('assets/background1.jpg');
}

.story-container {
  background-image: url('assets/background2.jpg');
}

.music-container {
  background-image: url('assets/background3.jpg');
}

/* Main Header and Titles */
.header {
  padding: 20px;
  margin-top: 280px; /* Adjust this value to move the title lower */
}

.title {
  font-size: 5rem;
  font-family: 'Arial Black', Arial, sans-serif;
  color: rgb(0, 0, 0);
  text-decoration: none;
}

.subtitle {
  font-size: 1.5rem;
  color: gray;
}

/* Page Headers and Titles */
.pageheader {
  padding: 10px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pagetitle {
  font-size: 3rem; /* Responsive sizing */
  font-family: 'Arial Black', Arial, sans-serif;
  color: rgb(0, 0, 0);
  margin: 10px 0;

}

/* Links and Buttons */
.links {
  display: flex;
  justify-content: center;
  gap: 100px;
  margin-top: 20px;
}

.link {
  background-color: #cf245f;
  background-image: linear-gradient(to bottom right, #fcd34d, #ef4444, #ec4899);
  border: none;
  border-radius: 0.25rem;
  color: #fff;
  cursor: pointer;
  font-family: 'Arial Black', Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.75rem;
  padding: 1rem 1.25rem;
  text-align: center;
  text-decoration: none;
  transition: transform 0.2s ease, background-color 0.2s ease;
  user-select: none;
}

.link:hover {
  transform: scale(1.1);
  background-color: rgba(255, 255, 255, 0.2);
}

@media (min-width: 1024px) {
  .link {
    font-size: 1.5rem;
    padding: 1rem 1.5rem;
    line-height: 2rem;
  }
}

/* Content Section */
.content {
  padding: 20px;
  font-size: 1.2rem;
  line-height: 1.6;
}

/* Footer */
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  font-weight: bold;
}

.footer img {
  height: 40px;
}

.footer p {
  margin: 0;
  font-size: 0.9rem;
}

/* Music Page Styles */
.music-container .embeds {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  margin: 20px 0;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.music-container .embed-item {
  flex: 1;
  max-width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Align QR codes with embeds */
}

.music-container .spotify-embed,
.music-container .itunes-embed {
  border-radius: 12px;
  width: 100%;
  height: 352px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.music-container .qr-image {
  width: 120px; /* Adjusted size */
  height: 120px;
  margin-top: 10px;
  padding: 20px;
}

.music-container .intro-text {
  color: black;
  font-family: 'Arial Black', Arial, sans-serif;

  font-size: 18px;
  line-height: 1.6;
  margin: 20px;
  text-align: justify;
}

.music-container .song-list {
  margin: 20px auto;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 90%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.music-container .song-column {
  flex: 1;
  min-width: 300px; /* Ensure columns stack on small screens */
}

.music-container .song-item {
  font-size: 14px;
  line-height: 1.4;
  display: flex;
  justify-content: flex-start;
  padding: 5px 0; /* Reduced padding */
}

.music-container .song-number {
  min-width: 30px;
  text-align: right;
  margin-right: 5px;
}

.music-container .song-title {
  flex-grow: 1;
  text-align: left;
}

/* Responsive Design */
@media (max-width: 768px) {
  .pagetitle {
    font-size: 2.5rem;
  }

  .music-container .embeds {
    flex-direction: column;
    gap: 40px;
  }

  .music-container .embed-item {
    max-width: 100%;
  }

  .music-container .song-item {
    font-size: 13px;
  }

  .music-container .song-list {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .pagetitle {
    font-size: 2rem;
  }

  .music-container .song-item {
    font-size: 12px;
  }

  .music-container .qr-image {
    width: 100px;
    height: 100px;
  }

  .music-container .intro-text {
    font-size: 16px; /* Smaller text for mobile */
  }
}
